// ローディング判定
jQuery(window).on("load", function() {
  jQuery("body").attr("data-loading", "true");
});

jQuery(function() {
  // スクロール判定
  jQuery(window).on("scroll", function() {
    let scrollTop = jQuery(this).scrollTop();
    let windowHeight = jQuery(this).height();
    let documentHeight = jQuery(document).height();

    if (100 < scrollTop) {
      jQuery("body").attr("data-scroll", "true");
    } else {
      jQuery("body").attr("data-scroll", "false");
    }

    if (documentHeight - (windowHeight + scrollTop) <= 0) {
      jQuery("body").attr("data-scroll-bottom", "true");
    } else {
      jQuery("body").attr("data-scroll-bottom", "false");
    }
  });

  /* ドロワー */
  jQuery(".js-drawer").on("click", function(e) {
    e.preventDefault();
    let targetClass = jQuery(this).attr("data-target");
    let ariaControls = jQuery(this).attr("aria-controls");
    jQuery("." + targetClass).toggleClass("is-checked");
    jQuery(body).toggleClass("is-checked");

    if (jQuery("#" + ariaControls).attr("aria-hidden") === "true") {
      jQuery("#" + ariaControls).attr("aria-hidden", "false");
    } else {
      jQuery("#" + ariaControls).attr("aria-hidden", "ture");
    }

    if (jQuery(this).attr("aria-expanded") === "true") {
      jQuery(this).attr("aria-expanded", "false");
    } else {
      jQuery(this).attr("aria-expanded", "ture");
    }
    return false;
  });

  jQuery(".sp-btn").on("click", function() {
    jQuery(this)
      .next()
      .slideToggle();
  });

  /* 電話リンク */
  let ua = navigator.userAgent;
  /*   if (ua.indexOf("iPhone") < 0 && ua.indexOf("Android") < 0) {
    jQuery('a[href^="tel:"]')
      .css("cursor", "default")
      .on("click", function(e) {
        e.preventDefault();
      });
  } */
});

if (window.matchMedia("(min-width: 769px)").matches) {
  /* ウィンドウサイズが 768px以上の場合に有効にしたい処理*/
  var _window = jQuery(window),
    _header = jQuery("header"),
    heroBottom;

  _window.on("scroll", function() {
    heroBottom1 = jQuery(".mv").height();
    heroBottom2 = jQuery(".header-logo").height();
    heroBottom = heroBottom1 - heroBottom2;
    if (_window.scrollTop() > heroBottom) {
      _header.addClass("transform");
    } else {
      _header.removeClass("transform");
    }
  });
  _window.trigger("scroll");
}

var urlHash = location.hash;
if (urlHash) {
  jQuery("body,html")
    .stop()
    .scrollTop(0);

  setTimeout(function() {
    var target = jQuery(urlHash);
    var position = target.offset().top - 70;
    //ランダムっぽいのでどちらにも対応
    jQuery("body,html")
      .stop()
      .animate({ scrollTop: position }, 300);
    console.log(header);
    console.log(position);
  }, 100);
}

jQuery('a[href^="#"]').click(function() {
  let id = jQuery(this).attr("href");
  let speed = 300;
  let target = jQuery("#" == id ? "html" : id);
  let position = jQuery(target).offset().top - 60;
  /*   if ("fixed" !== jQuery("header").css("position")) {
    position = jQuery(target).offset().top;
  } */

  jQuery("html, body").animate(
    {
      scrollTop: position
    },
    speed
  );
  return false;
});

jQuery(function() {
  // aimation呼び出し
  if (jQuery(".js-scroll-trigger").length) {
    scrollAnimation();
  }

  // aimation関数
  function scrollAnimation() {
    jQuery(window).scroll(function() {
      jQuery(".js-scroll-trigger").each(function() {
        let position = jQuery(this).offset().top,
          scroll = jQuery(window).scrollTop(),
          windowHeight = jQuery(window).height();

        if (scroll > position - windowHeight + 200) {
          jQuery(this).addClass("is-active");
        }
      });
    });
  }
  jQuery(window).trigger("scroll");
});

// メニュースライダーをスタート
jQuery(".mv").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  fade: true,
  arrows: false,
  speed: 1000,
  pauseOnHover: false
});
